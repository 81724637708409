/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Button from '@mui/joy/Button';
// import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../components/TwoSidedLayout';

export default function Hero() {
  return (
    <TwoSidedLayout>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        Babystep Technologies
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Build Great Software
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        We are a small team of developers who are passionate about building great software to solve real business problems.
      </Typography>
      <Button size="lg" endDecorator={<ArrowForward fontSize="large" />}>
        Contact Us
      </Button>
    </TwoSidedLayout>
  );
}
